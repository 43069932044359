<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <view-intro heading="Миний сургалтууд" link="components/simple-tables" />
    <v-dialog v-model="addDialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on">
          Н+ {{ courses.length }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Нэмэx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeEdit"> Цуцлаx </v-btn>
          <v-btn color="blue darken-1" text @click="saveItem"> Xадгалаx </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-card icon="mdi-clipboard-text" icon-small color="accent">
      <v-simple-table v-if="courses&&courses.length>0">
        <thead>
          <tr>
            <th class="primary--text">ID</th>
            <th class="primary--text">Name</th>
            <th class="primary--text">Date</th>
            <th class="text-right primary--text">Price</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item,index) in courses" v-bind:key="index" @click="_detail(item,index)"> 
            <td>{{index+1}}</td>
            <td>{{item.name}}</td>
            <td>{{item.createdAt}}</td>
            <td class="text-right"> <span v-if="item.price">{{item.price}}</span></td>
          </tr>
 
        </tbody>
      </v-simple-table>
    </material-card>

    <div class="py-3" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
const fb = require("../firebaseConfig.js");

export default {
  name: "RegularTablesView",
  data: () => ({
    addDialog: false,
    editedItem: {
      name: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
    },
    courses: [],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    this._readCourses();
  },

  methods: {
    _detail(item,index){
        this.$router.push({ name: 'CourseDetail', params: { courseId: item.id} })
    },
    closeEdit() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      console.log(this.editedIndex, this.editedItem);
      this.editedItem.createdAt = new Date();
      if (this.editedIndex === -1) {
        // Object.assign(this.participants[this.editedIndex], this.editedItem);
        fb.db
          .collection("courses")
          .doc()
          .set({
            name: "Los Angeles",
          })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      } else {
        docRef = fb.db.collection("courses/").doc(this.editedItem.id);
      }
      this.closeEdit();
    },
    _readCourses() {
      fb.db
        .collection("courses")
        // .where("capital", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let course = doc.data()
            course.id = doc.id
            course.ref = doc.ref
            this.courses.push(course);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>
